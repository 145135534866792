// @use 'sass:math';
// @function torem($value) {
//     @return math.div($value, 16px) * 1rem; 
// }

// @function torem($value) {
//     $remValue: ($value / 16) + rem; 
//     @return $remValue;
// }

/* placeholder */
@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

$breakpoints: ('custom-small-mobile-max': (max-width: 359px),
    'portrait-mobile-max': (max-width: 575px),
    'mobile-min': (min-width: 576px),
    'mobile-max': (max-width: 767px),
    'ipad-min': (min-width: 768px),
    'ipad-max': (max-width: 991px),
    'desktop-min': (min-width: 992px),
    'custom-desktop-max': (max-width: 1024px),
    'desktop-max': (max-width: 1199px),
    'large-desktop-min': (min-width: 1200px),
    'medium-large-desktop-min': (min-width: 1366px),
    'medium-large-desktop-max': (max-width: 1400px),
);

@mixin breakpoint($name) {
    @if map-has-key($breakpoints, $name) {
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    }

    @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}


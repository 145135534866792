@import '../src/assets/scss/variables';
@import '../src/assets/scss/mixins';
// @import ' /assets/scss/mixins';
/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/scss/bootstrap";


// 
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "~ngx-toastr/toastr";
/*-------------------------------------------------------------
# General
--------------------------------------------------------------*/

$breakpoints: (
  'custom-small-mobile-max': (max-width: 359px),
  'portrait-mobile-max': (max-width: 575px),
  'mobile-min': (min-width: 576px),
  'mobile-max': (max-width: 767px),
  'ipad-min': (min-width: 768px),
  'ipad-max': (max-width: 991px),
  'desktop-min': (min-width: 992px),
  'custom-desktop-max': (max-width: 1024px),
  'desktop-max': (max-width: 1199px),
  'large-desktop-min': (min-width: 1200px),
  'medium-large-desktop-min': (min-width: 1366px),
  'medium-large-desktop-max': (max-width: 1400px),

);

@mixin breakpoint($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  @else {
    @warn "Couldn't find a breakpoint named `#{$name}`.";
  }
}

body {

  color: #333;
}

a {
  text-decoration: none;
  color: #0880e8;
}

a:hover {
  color: #2b99f8;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  color: #333;
}

p {
  line-height: 1.60em;
  font-size: 15px;
  color: #555;
}


ul {
  >li {
    line-height: 1.60em;
    font-size: 15px;
    color: #555;
  }
}

strong {
  color: #333;
}

html,
body {
  &.scroll-hide {
    position: relative;
    overflow-y: hidden;
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

gallery-thumbs {
  display: none !important;
}

.main-heading-all {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 20px;

  @include breakpoint(ipad-min) {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0880e8;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #2194f7;
  color: #fff;
}



.back-to-top.active {
  visibility: visible;
  opacity: 1;
}



@media screen and (min-width: 768px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

section {
  padding: 120px 0;
  overflow: hidden;

  @include breakpoint(mobile-max) {
    padding: 70px 0;
  }
}


.section-bg {
  background-color: #f6f6f6;
}

.section-title {
  text-align: center;

}

.section-title h2 {
  font-size: 40px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 30px;
  padding-bottom: 0;
  color: #333;
}

.section-title p {
  margin-bottom: 0;

}


@media (max-width: 576px) {
  .section-title h2 {
    font-size: 30px;
  }

  .accor {
    padding: 15px 3px !important;
  }

  #job-sub-headig {
    font-size: 17px !important;
    padding-bottom: 5px;

  }

  .nav-tabs .nav-link {
    font-size: 16px !important;
    font-weight: 500;

  }
}


// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .accor {
    padding: 15px 3px !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}




//end with here responsive

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact {
  background: url("./assets/img/contact-bg.png") left center no-repeat;
  position: relative;
}

.contact:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.contact .container {
  position: relative;
}

.contact .info {
  width: 100%;
}



.contact .info h4 {

  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #333;
}

.contact .info p {

  margin-bottom: 0;
  font-size: 14px;
  color: #444444;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;

}



.contact .php-email-form .error-message {
  // display: none;
  color: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  // display: none;
  color: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  border: 0;
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #0880e8;
  color: #fff;
}


.hm-slider {
  position: relative;



  .slider {
    background-size: cover;
    height: 30vh;


    @include breakpoint(ipad-min) {
      height: 100vh;
    }
  }

  .owl-nav {
    .owl-prev {
      position: absolute;
      top: 45%;
      left: 0;
      background-color: transparent;

      &:hover {
        background: transparent;
      }
    }

    .owl-next {
      position: absolute;
      top: 45%;
      right: 0;
      background-color: transparent;

      &:hover {
        background: transparent;
      }
    }
  }
}

.icon-boxes {
  margin-top: -55px;

  @include breakpoint(mobile-max) {
    margin-top: 0;
  }
}

.portfolio-section {
  position: relative;

  .owl-nav {

    .owl-prev {
      position: absolute;
      top: 32%;
      left: -11px;
      padding: 11px 6px;


      img {
        width: 18px;
      }

      &:hover {
        background: #fff !important;
        color: #FFF;
        text-decoration: none;

      }
    }



    .owl-next {
      position: absolute;
      top: 32%;
      right: -11px;
      background-color: #fff;
      padding: 11px 6px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

      img {
        width: 18px;
      }

      &:hover {
        background: #fff !important;
        color: #FFF;
        text-decoration: none;

      }
    }
  }
}

//banner responsive all pages
.portfoio {
  &__caption {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 10px;

    h4 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 20px;
    }

    &__sub-title {
      font-size: 14px;
      color: #808080;
    }

    &__day-nights-places {
      font-size: 12px;
      color: #808080;
    }
  }

}





.read-morebtn {
  background-color: #ed1c24;
  border: 0px;
  padding: 8px 34px;
  border-radius: 9px;
  margin-top: 10px;

  &:hover,
  &:active,
  &:focus {
    background-color: #2d3091;
  }
}

.icon-clr {
  background: #ffdbdb;
  padding: 6px 8px;
  border-radius: 23px;
  color: red;
}

.pd-0 {
  padding: 0px;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  

  @include breakpoint(ipad-min) {
    border-radius: 15px !important;
  }
  @include breakpoint(mobile-max) {
    position: relative;
  }



}

.cdk-overlay-pane {
  @include breakpoint(mobile-max) {
    max-width: 100vw !important;
    width: 900px;
    padding: 10px;
  }
}

.cdk-overlay-container {
  z-index: 10000;

  .cdk-global-overlay-wrapper {
    @include breakpoint(mobile-max) {
      align-items: inherit !important;
      border-radius: 23px;
      color: red;

    }
  }
}

//all inner page top banner
//top banner


.subbanner {
  position: relative;
  background-image: url('../src/assets/img/itinary.jpg');
  background-size: cover;
  width: 100%;
  height: 300px;

  @include breakpoint(mobile-max) {
    width: 100%;
    height: 150px;
  }

  &__title {
    position: absolute;
    color: #fff;
    z-index: 100;
    width: 100%;
    text-align: center;
    z-index: 1;
    top: 175px;
    text-shadow: 2px 2px 4px #000000;


    @include breakpoint(mobile-max) {
      text-align: center;
      left: 0;
      right: 0;
      top: 55px;
    }

    .primary-title {
      font-weight: 700;
      font-size: 40px;
      color: $white;

      @include breakpoint(mobile-max) {
        font-size: 30px;
      }
    }
  }
}

.btn-close {
  position: absolute;
  top: 16px;
  right: 21px;
  display: inline-block;
  background-position: 100% 0;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  background-position-x: center;
  background-position-y: center;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;


  &:hover {
    background-color: transparent;
  }

  &:focus {
    border: 0px;
    outline: 0;
    box-shadow: 0 0 0;
  }

}



.padding-section {
  padding-top: 60px;
  padding-bottom: 60px;

  @include breakpoint(ipad-max) {
    padding: 20px 0;
  }

  @include breakpoint(mobile-max) {
    padding: 20px 0;
  }
}



@include breakpoint(mobile-min) {
  @include breakpoint(mobile-max) {

    .container-sm,
    .container {
      max-width: 100% !important;
    }
  }
}


@include breakpoint(desktop-min) {

  @include breakpoint(desktop-max) {

    .navbar {
      &>ul {
        &>li {
          position: relative;
          white-space: nowrap;
          padding: 10px 0 10px 6px !important;
        }
      }
    }
  }

}




ng-image-fullscreen-view {
  position: relative !important;
  z-index: 111111 !important;

}


.caption.show {
  display: none !important;
}


.lightbox-wrapper {

  .close {
    background-color: transparent !important;
    color: #fff;
    background-image: url("../src/assets/img/close-x.svg") !important;
  }
}


.accor {
  .mat-expansion-panel-body {
    padding: 0px;
  }

}

@include breakpoint(mobile-max){
.container{
  padding-left: 20px;
  padding-right: 20px;
}
}

.error{
  font-size: 16px;
  color:$primary-color;
}

.frm-text {
  color: #333;
}

#toast-container {
  position: fixed;
  bottom: 0;
  z-index: 9999999;
  overflow: visible !important;
}

/* Center the Toastr messages */
/* Center Toastr messages at the top-center */
// .toast-top-center {
//   left: 50% !important;
//   transform: translateX(-50%) !important;
//   text-align: center !important;
// }

.frm-text {
  color: #333;
}


.hm-slider {
  @include breakpoint(mobile-max) {
    .slider {
      height: calc(100vh - 55px);
    }
  }

  .slider {
    height: 90vh;
  }
}

.cdk-global-scrollblock {
  overflow-y: scroll !important;
}

.frm-text {
  color: #333;
}

#toast-container {
  position: fixed;
  bottom: 0;
  z-index: 9999999;
  overflow: visible !important;
}

/* Center the Toastr messages */
/* Center Toastr messages at the top-center */
// .toast-top-center {
//   left: 50% !important;
//   transform: translateX(-50%) !important;
//   text-align: center !important;
// }


.owl-theme .owl-nav .owl-prev {
  background: #ffffffd6 !important;
}

.owl-theme .owl-nav .owl-next {
  background: #ffffffd6 !important;
}

// .owl-theme .owl-item {
//   width: 250px;
// }

.btn:disabled{
  cursor: not-allowed;
}
.needs-validation {
  .btn{
    background: $primary-color;
    border: 0px !important;
  }
}

button[disabled=disabled], button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}


